.bio {
  font-size: 1.25rem;
}

.bio-container {
  margin: 0 auto;
  max-width: 1100px;
  width: 90vw;
}

.bio-heading {
  font-size: 1.25rem;
  margin: 0;
  font-size: larger;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.bio-profiles {
  align-content: center;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 60px);
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.bio-image-container {
  margin: 0 0 4rem;
  padding: 0;
  /* height: 300px; */
}

@media (min-width: 900px) {
  .bio-heading {
    font-size: 1.75rem;
  }

  .bio-container {
    display: grid;
    gap: 6rem;
    grid-template-columns: 1fr 400px;
  }

  .bio-image-container {
    margin-bottom: 0;
  }
}

.bio-length-control {
  margin: 1rem auto 3rem;
  max-width: 90vw;
  width: 300px;
}

.bio-fieldset {
  border: none;
  padding: 0;
}

.bio-legend {
  color: var(--text-muted);
  font-size: 0.75rem;
  font-variant: small-caps;
  font-weight: 400;
  letter-spacing: 0.15em;
  margin: 0;
  padding: 0;
  text-transform: lowercase;
}

.bio-length-options {
  align-items: center;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(6, 1fr);
}

.bio-option {
  cursor: pointer;
  height: calc((300px - 10rem) / 6 + 1rem);
  position: relative;
}

.bio-input {
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.bio-label-text {
  clip: rect(1px, 1px, 1px, 1px);
  color: var(--text-muted);
  font-weight: 400;
  height: 1px;
  letter-spacing: 0.2em;
  overflow: hidden;
  position: absolute;
  top: 80%;
  white-space: nowrap;
  width: 1px;
}

.bio-option:first-of-type .bio-label-text,
.bio-option:last-of-type .bio-label-text {
  clip: auto;
  height: auto;
  overflow: visible;
  width: 60px;
}

.bio-option:first-of-type .bio-label-text {
  left: 0;
  text-align: left;
}

.bio-option:last-of-type .bio-label-text {
  right: 0;
  text-align: right;
}

.bio-label {
  cursor: pointer;
  font-size: 0.75rem;
}

.bio-label::before,
.bio-label::after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  padding-bottom: calc(1rem - 2px);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
}

.bio-label::before {
  border: 1px solid var(--pink);
  transition: 200ms background linear;
  z-index: 10;
}

.bio-label::after {
  background: var(--pink);
  opacity: 0;
  transition: 200ms opacity linear;
}

:checked + .bio-label::before,
:hover + .bio-label::before,
:focus + .bio-label::before {
  border-color: var(--text-pink);
}

:focus + .bio-label::before {
  outline: 2px solid var(--pink);
  outline-offset: 0.125rem;
}

:checked + .bio-label::after {
  opacity: 1;
}

.bio-social {
  margin-top: 3rem;
}

.bio-connect {
  color: var(--text-muted);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.2em;
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
}

.bio-link {
  background: var(--text-pink);
  border-radius: 50%;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  padding: 1.125rem;
  transition: all 200ms linear;
  transition-delay: 150ms;
}

.bio-link:focus,
.bio-link:hover {
  background: var(--text-pink);
  outline: none;
  transition-delay: 0ms;
}

.bio-link path {
  fill: var(--white);
  transition: 200ms fill linear;
  transition-delay: 150ms;
}

.bio-link:focus path,
.bio-link:hover path {
  fill: var(--blue);
  transition-delay: 0ms;
}
