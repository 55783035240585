.footer {
    background: var(--background);
    color: var(--text-muted);
    display: flex;
    font-size: 0.875rem;
    justify-content: space-between;
    padding: 2rem;
    text-align: center;
  }
  
  .footer-nav {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  
  .footer-nav a {
    color: inherit;
    text-decoration: none;
  }
  
  .footer-nav a:focus,
  .footer-nav a:hover {
    text-decoration: underline;
  }
  