.site-header {
    background: var(--black);
    display: flex;
    gap: 1.5rem;
    padding: 1rem 2rem;
    position: relative;
    top: 0;
    z-index: 1000;
  }
  
  .site-header::after {
    background-image: linear-gradient(
      to right,
      var(--pink) 0%,
      var(--yellow-dark) 50%,
      var(--blue) 100%
    );
    content: '';
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }
  
  .home {
    color: var(--white);
    font-weight: 400;
    position: sticky;
    text-decoration: none;
  }
  
  .site-nav a {
    color: var(--white);
    text-decoration: none;
  }
  