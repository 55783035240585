 .block {
    background: var(--background);
    border-bottom: 1px solid var(--gray-light);
    padding: 5rem 5vw;
    transition: 200ms background linear;
    position: relative;
  }

  .block.yellow {
    background: var(--yellow);
    border-color: var(--yellow-dark);
  }

  .block.dirty-white {
    background: var(--dirty-white);
    border-color: var(--white);
  }