@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,100;1,300;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,700;1,700&display=swap");
:root {
  --white: white;
  --dirty-white:#f0f0f0;
  --text: #403c4a;
  --text-emphasized: #201d29;
  --text-muted: #78757f;
  --text-pink: #c10b7e;
  --yellow: #ffe742;
  --yellow-dark: #ddc62c;
  --pink: #ff87d4;
  --pink-faded: #f5f2fc;
  --pink-dark: #c10b7e;
  --blue: #87fffb;
  --blue-dark: #63ddd9;
  --black: #171321;
  --gray-light: #d4d0e0;
  --gray-medium: #b1acb9;
  --font-family: jwf, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --background: var(--white);
  --foreground: var(--black);
  --footnote-hover: var(--pink-dark);
}

* {
  font-family: var(--font-family);
  overscroll-behavior: auto;
  padding: 0px;
  margin: 0px;
}
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 1.45;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

.outer {
  color: var(--text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--foreground);
  font-weight: 900;
  line-height: 1.1;
  transition: 200ms color linear;
}

a {
  color: var(--text-pink);
  font-weight: 400;
}

strong,
b {
  font-weight: 600;
}

img {
  max-width: 100%;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}
.cools {
  mix-blend-mode: overlay;
  z-index: 10;
}

.attractor {
  z-index: 10;
}

/* ------------------------ */

