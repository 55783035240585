.hero {
  height: 70vh;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 700px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 90vw;
  z-index: 1;
}

.hero-first-line {
  display: block;
  font-size: clamp(20px, 25.5vw, 120px);
  line-height: 0.95;
  color: var(--black);
  font-weight: 900;
}

.hero-first-line.maximum-boops {
  font-size: clamp(20px, 26.5vw, 120px);
}

.hero-box {
  background: var(--black);
  color: var(--background);
  display: block;
  margin: auto;
  text-align: center;
  font-size: clamp(20px, 10.5vw, 120px);
  font-weight: 900;
  line-height: 1;
  padding: 0 0.5rem;
  -webkit-transition: 200ms background linear;
  transition: 200ms background linear;
}

.hero-tagline {
  display: block;
  font-size: var(--size, 2.42vw);
  margin-top: 0.125rem;
  font-weight: 900;
}

.love {
  background: url('https://res.cloudinary.com/jlengstorf/image/upload/q_auto,f_auto/v1593755650/jason.af/love.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
  display: inline-block;
  height: 1.2em;
  margin: -0.1em -3px;
  position: relative;
  top: var(--top, -0.05em);
  transform: scale(var(--scale, 1.1)) rotate(var(--rotation, -11deg)) translateY(0.1em);
  transform-origin: center;
  width: 1.6em;
}

.hero-cycle {
  background: transparent;
  border: none;
  color: var(--text-muted);
  display: block;
  font-size: 0.75rem;
  font-variant: small-caps;
  font-weight: 400;
  letter-spacing: 0.2em;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 30px;
  z-index: 20;
}

.hero-cycle.cycle-is-active {
  animation-duration: 500ms;
  animation-name: spin-hero-cycle;
  animation-iteration-count: 1;
}

.hero-cycle img {
  width: 100%;
}

@keyframes spin-hero-cycle {
  from {
    transform: rotate(-1turn);
  }

  to {
    transform: rotate(0);
  }
}

.temp-tagline {
  display: block;
  font-size: 2.42vw;
  margin-top: 0.125rem;
  color: var(--black);
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}
