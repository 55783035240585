.intro {
    margin: 0 auto 3rem;
    max-width: 90vw;
    width: 900px;
  }
  
  .intro:only-child {
    margin-bottom: 0;
  }
  
  .intro-headline {
    font-size: 11vw;
    margin: 0;
    text-align: center;
  }
  
  .intro-lede {
    font-size: 1.25rem;
    margin: 1rem 0 0;
    text-align: center;
  }
  
  .intro-lede p {
    margin: 1rem 0 0;
  }
  
  @media (min-width: 600px) {
    .intro-headline {
      font-size: 7vw;
    }
  }
  
  @media (min-width: 950px) {
    .intro-headline {
      font-size: 4.125rem;
    }
  }
  